/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import NonAuthLayout from 'app/components/NonAuthLayout';
// layouts Format
import VerticalLayout from 'app/components/VerticalLayout';
import { TOAST_TIME } from 'app/config';
import { authRoutes, userRoutes } from 'app/route/allRoutes';
import ProtectedRoute from 'app/route/ProtectedRoute';
import { AxiosInterceptor } from 'app/services';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from 'store';
import { GlobalStyle } from 'styles/global-styles';
import 'react-image-lightbox/style.css';

export function App() {
  const { layoutType } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const { i18n } = useTranslation();
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (layoutType) {
      case 'horizontal':
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const Layout = getLayout();
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet
          titleTemplate="Admin Panel"
          defaultTitle="Admin Panel"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="Admin Panel" />
        </Helmet>
        <AxiosInterceptor>
          <Switch>
            {authRoutes.map((route, idx) => (
              <ProtectedRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact={route.exact}
              />
            ))}
            {userRoutes.map((route, idx) => (
              <ProtectedRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            <Redirect from="*" to="/notFound" />
          </Switch>
        </AxiosInterceptor>
        <ToastContainer
          position="top-right"
          autoClose={TOAST_TIME}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <GlobalStyle />
      </BrowserRouter>
    </HelmetProvider>
  );
}
