import {
  Admin,
  AdminLoginPayload,
  AdminWithAuth,
  Filter,
  ListResult,
} from '../models';
import Axios from './';

/**
* * get admin list (https://jdc-app-dev.appicidea.com/doc/#/Admin/adminsControllerListAllAdmins)
+*/

export interface GetAdminListParams extends Filter {}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<AdminWithAuth>>('/admins', {
    params,
  });
  return data;
};

/**
* * create one admin (https://jdc-app-dev.appicidea.com/doc/#/Admin/adminsControllerCreateOneAdmin)
+*/

export interface CreateOneAdminParams {
  displayName: string;
  loginName: string;
  password: string;
  email?: string;
}

export const createOneAdmin = async (params: CreateOneAdminParams) => {
  const { data } = await Axios.post<Admin>('/admins', params);
  return data;
};

/**
* * get one admin (https://jdc-app-dev.appicidea.com/doc/#/Admin/adminsControllerGetOneAdminByAdminId)
+*/

export const getOneAdmin = async (adminid: string) => {
  const { data } = await Axios.get<AdminWithAuth>(`/admins/${adminid}`);
  return data;
};

/**
* * update one admin (https://jdc-app-dev.appicidea.com/doc/#/Admin/adminsControllerUpdateOneAdminByAdminId)
+*/

export interface UpdateOneAdminParams {
  displayName: string;
}

export const updateAdmin = async (
  adminId: number | string,
  params: UpdateOneAdminParams,
) => {
  const { data } = await Axios.patch<null>(`/admins/${adminId}`, params);
  return data;
};

/**
* * reset one admin password (https://jdc-app-dev.appicidea.com/doc/#/Admin/adminsControllerResetAdminPassword)
+*/

export const resetAdminPassword = async (
  adminId: number | string,
  params: { newPassword?: string },
) => {
  const { data } = await Axios.post<string>(
    `admins/${adminId}/resetPassword`,
    params,
  );
  return data;
};

/**
* * delete one admin (https://jdc-app-dev.appicidea.com/doc/#/Admin/adminsControllerDeleteOneAdminByAdminId)
+*/

export const deleteOneAdmin = async (adminId: number | string) => {
  const { data } = await Axios.delete<string>(`/admins/${adminId}`);
  return data;
};

/**
* * get admin profile (https://jdc-app-dev.appicidea.com/doc/#/Admin%20Me/adminMeControllerGetCurrentAdmin)
+*/

export const getProfile = async () => {
  const { data } = await Axios.get<ListResult<Admin>>('/admins/me/profile');
  return data;
};

/**
* * admin login (https://jdc-app-dev.appicidea.com/doc/#/Admin/adminsControllerLoginNamePasswordSignIn)
+*/

export const adminLogin = async (params: AdminLoginPayload) => {
  const { data } = await Axios.post<string>(
    '/admins/passwordAuth/SignIn',
    params,
  );
  return data;
};

/**
* * admin logout (https://jdc-app-dev.appicidea.com/doc/#/Admin%20Me/adminMeControllerAdminLogout)
+*/

export const adminLogout = async () => {
  const { data } = await Axios.post<any>('/admins/me/logout');
  return data;
};
