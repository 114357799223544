import React from 'react';
import { Route } from 'react-router-dom';
// import { useAdminPermission } from 'store/Permission';

interface Props {
  component: any;
  isAuthProtected: boolean;
  layout?: any;
  exact?: boolean;
  path: string;
}

const ProtectedRoute = ({
  component: Component,
  isAuthProtected,
  layout: Layout,
  exact,
  path,
  ...rest
}: Props) => {
  // const { Auth } = useSelector((rootState: RootState) => rootState.auth);
  // const { hasPermission } = useAdminPermission();
  return (
    <Route
      {...rest}
      path={path}
      exact
      render={props => {
        // if (!Auth) {
        //   return (
        //     <Redirect
        //       to={{ pathname: '/login', state: { from: props.location } }}
        //     />
        //   );
        // }

        // permission check here

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default ProtectedRoute;
