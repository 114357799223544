import { AdminFormPage, AdminListPage } from 'app/pages/AdminPage';
import { LoginPage, LogoutPage } from 'app/pages/Authentication';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { JewelryDetailsPage, JewelryListPage } from 'app/pages/JewelryPage';
import { MaintenanceFormPage } from 'app/pages/MaintenancePage';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import {
  NotificationFormPage,
  NotificationListPage,
} from 'app/pages/NotificationPage';
import { UserListPage } from 'app/pages/UserPage';
import { Redirect } from 'react-router-dom';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
}
const userRoutes: Route[] = [
  // admin
  { path: '/admins', component: AdminListPage },
  { path: '/admins/new', component: AdminFormPage, exact: true },
  { path: '/admins/edit/:adminId', component: AdminFormPage, exact: true },
  // {
  //   path: '/admins/edit/:adminid/:password',
  //   component: AdminFormPage,
  //   exact: true,
  // },

  // user
  { path: '/users', component: UserListPage },

  // notifications
  { path: '/notifications', component: NotificationListPage },
  { path: '/notifications/new', component: NotificationFormPage },

  // jewelryForm
  { path: '/jewelry-form', component: JewelryListPage },
  { path: '/jewelry-form/view/:jewelryFormId', component: JewelryDetailsPage },

  // maintenances

  { path: '/maintenances', component: MaintenanceFormPage },
  { path: '/maintenances/new', component: MaintenanceFormPage },
  {
    path: '/maintenances/edit/:maintenanceId',
    component: MaintenanceFormPage,
  },

  // home page
  { path: '/', component: HomePage },

  // 404 page
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
